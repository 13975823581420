import axios, { AxiosInstance, AxiosResponse } from 'axios'
import qs from 'qs'
import * as models from './models'
import AuthToken from '@/auth/auth-token'

class Document {
  protected readonly document: AxiosInstance;

  constructor () {
    const document = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })

    this.document = document
  }

  handleSuccess (response: AxiosResponse) {
    return response
  }

  public post = async (data: models.Document) => {
    await AuthToken.setAuth(this.document)

    return this.document({
      url: 'v1/doc',
      method: 'POST',
      data: data
    })
  }

  public put = async (docId: string, data: models.Document) => {
    await AuthToken.setAuth(this.document)

    return this.document({
      url: `v1/doc/${docId}`,
      method: 'PUT',
      data: data
    })
  }

  public get = async (docId: string) => {
    await AuthToken.setAuth(this.document)

    return this.document({
      url: `v1/doc/${docId}`,
      method: 'GET'
    })
  }

  public search = async (partyIds: Array<string>, tags: any, offset: number, limit: number, document_file_name: string, filteredIds: any) => {
    await AuthToken.setAuth(this.document)

    return this.document({
      url: 'v1/docs/search',
      method: 'GET',
      params: {
        root_key: 'ipro_portal',
        party_ids: partyIds,
        tags: JSON.stringify(tags),
        excluded_subcategories: filteredIds,
        offset: offset || 0,
        limit: limit || 10,
        document_file_name: document_file_name
      },
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  public delete = async (docId: string) => {
    await AuthToken.setAuth(this.document)

    return this.document({
      url: `v1/doc/${docId}`,
      method: 'DELETE'
    })
  }
}

export default new Document()
